<template lang="pug">
  div
    //- Loading(v-if="isLoading")
    .container-fluid.wrapper(v-if="!isLoading")
      #title.py-2(@click="callRockyInfo()")
        .d-inline-block.text-left
          .small {{nameKana}}
          div.rockyNameTitle {{rockyName}}
      //- Map
      #map-container(v-if="isMapComponentShow")
        #rocky-map-inner.position-relative
          Map(ref="map" 
              :markerListFromParent= "markerInfo", 
              :zoom=10,
              :gestureHandling = "gestureHandling",
              :lat="Number(mapCenterLat)" , 
              :lng="Number(mapCenterLng)",
              :isSearchCtlShow = "false",
              :isCsSpaCtlShow = "false",
              :isReturnRockyControlShow = "true",
              :isRockyInfo = "false",
              :isRockInfo = "true",
              :rockId = rockId,
              :rockName = "rockName",
              v-if="isMapDataAcquired")
      //-ユーザー記録へのリンク
      #rock-record-link
        .create-record-row
          .linkExist(v-if="checkinHistoryExist" @click="callRouterPushCheckin()") この岩場のチェックイン履歴
        .create-record-row
          .linkExist(v-if="recordExist" @click="callRouterPushRecord()") この岩場の過去の記録
      //- ルート一覧の表示
      #route-info-container(v-if="isRouteListShow")
        #route-list
          #info-container(v-if="isShowInfo")
            .info(v-for="info in this.remarkList")
              i.fas.fa-info-circle 
              |{{info}}
          h5 <b>{{this.rockName}}</b> のルート一覧
          //-未ログイン時の表示↓↓↓↓↓--------------------------
          #overlay-wrapper
            #overlay-not-logined(v-if="(isLogin == false && isDemoLocky == false) || (isLogin == true && $user.payment == null && isDemoLocky == false) ")
              #info-area
                |この続きは有料プラン限定機能です。
                |<br>有料プランにご登録頂きますと、<br>岩場情報やルート写真の参照、<br>登攀記録の作成が可能になります。
                |<br>
                span 有料プラン（月額）は初月無料！
              #button-area
                button.btn.btn-primary(@click="callRouterPush('/user/payment/add_input')") 有料プランお申込み
                button.btn.btn-primary.btn-sm(@click="callRouterPush('/login')" v-if="isLogin == false") ログインはこちら
          //-未ログイン時の表示↑↑↑↑↑--------------------------
          #route-list-table
            div.my-4(v-if="falseInfo") 情報の取得に失敗しました
            b-table.table(small v-if="!falseInfo" thead-class="#table-header" borderless striped hover="" :items="items" :fields="fields")
              template(v-slot:cell(route_name)="data")
                .div-for-router(@click="callRouteImage(data)" v-if="!data.item.isRouteRecordExist") {{ cnvRouteName(data.value) }}
                .div-for-router(@click="callRouteImage(data)" v-if="data.item.isRouteRecordExist")
                  b-button.link-button(size="sm" variant="link" @click="callRouteImage(data)" ) {{ cnvRouteName(data.value) }}
              template(v-slot:cell(level)="data")
                .div-for-router(@click="callRouteImage(data)") {{ data.value }}
              template.col-make-record(v-slot:cell(makeRecord)="data")
                //記録作成アイコン
                .col-make-record(ontouchstart="")
                  .inner-box
                    router-link(:to="{ name: 'record_edit', query: { rockyId:rockyId, rockId:rockId, routeId:data.item.routeId, ref:'rockId'}}")
                      i.fas.fa-edit
                      span.col-make-recordtext 記録を作成する
      FeedBack
</template>

<script>
import Map from '@/components/Map.vue'
import Loading from '@/components/Loading.vue'
import FeedBack from '@/components/feed_back/FeedBackLink.vue'
import axios from 'axios'

export default {
  // データオブジェクト
  data() {
    return {
      //デフォルト値
      isRouteListShow:false,
      rockName:'',
      isLogin:false,
      isDemoLocky:false,
      rockyId:null,
      rockId:null,     
      items:[],
      dbRouteList:[],
      //Map上に岩マーカーを表示している場合true
      isMapComponentShow:false,
      isRockMakerShowOnMap:false,
      isRockyImgShow:true,
      //MAP表示
      //岩場アイコン表示用
      rockyLngLat:null,
      mapCenterLat:null,
      mapCenterLng:null,
      //MAPコンポーネント引き渡し用
      markerInfo:null,
      rockyInfoApiData:null,
      isMapDataAcquired:false,
      gestureHandling:'greedy',
      //記録、チェックイン履歴へのリンク
      checkinHistoryExist:false,
      recordExist:false,
      //岩画面表示
      rockyName:'',
      nameKana: '',
      address:null,
      level:null,
      csList:[],
      spaList:[],
      rockList:null,
      rockyInfo:null,
      rockyImgPath:null,
      remarkList:[],
      isShowInfo:false,
      isLoading:true,
      //rocky-InfoAPIの戻り値を保持
      rockyDetailJson:null,
      //ルート一覧のフィールド定義
      fields: [
        {
          key: 'route_name',
          label: 'ルート名',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white', width: '70%'},
          formatter: 'getRouteChild'
        },
        {
          key: 'level',
          label: '難易度',
          sortable: true,
          thStyle: { backgroundColor: '#171D32',color: 'white', width: '20%'}
        },
        {
          key: 'makeRecord',
          label: '',
          sortable: false,
          thStyle: { backgroundColor: '#171D32',color: 'white', width: '10%'}
        }
      ],
      falseInfo: false,
    }
  },
  // 算出プロパティ
  computed: {
  },
  watch: {
    '$route' (to, from) {
      console.log('watch')
    }
  },
  // インスタンス作成後
  created() {
    this.createdProcess()
  },
  // メソッド
  methods: {
    //-------------------------------------------------
    // created時の処理
    // 岩場IDから対象の岩場情報を取得して表示する
    //-------------------------------------------------
    async createdProcess(){

      this.rockyId = this.$route.params.rockyId
      this.rockId = this.$route.params.rockId
      this.rockName = this.$route.params.rockName,
      this.rockyInfoApiData = this.$route.params.rockyDetailJson

      //未ログインかつ、デモ表示用岩場の場合はルートを表示する
      this.isLogin = this.$user.isLogin
      if(this.rockyId == process.env.VUE_APP_DEMO_ROCKY_ID){
        this.isDemoLocky = true 
      }

      this.isRouteListShow = true
      this.isMapComponentShow = true

      //ログインしている場合、岩場に対する記録とチェックイン履歴の有無を取得する
      if(this.isLogin){
        await this.setRecordAndCheckinHistoryExists()
      }

      //パラメータから岩場情報が取得できなかった場合は取得する
      //TODO:パラメータで受け取ったJSONの整合性チェック(検討する)
      if(this.rockyInfoApiData == undefined){
        await this.getRockyMasterData(this.rockyId,this.isLogin)
        .then(response => {
          this.rockyInfoApiData = response.data[0]
        })
        .catch(function (error) {
          console.log('getRockyMasterData() error')
        })
      }

      //データが0件の場合、検索画面に戻る
      if(this.rockyInfoApiData.length == 0){
        await this.asyncDialog('該当する岩データが存在しません。')
        //ルート一覧画面にもどる
        this.$router.push('/rockyInfo/' + this.rockyId)
      }

      //取得した値をセットする
      console.log(this.rockyInfoApiData)
      let rockyDataRecord = this.rockyInfoApiData
      this.rockyId = rockyDataRecord.rockyId
      this.rockyName = rockyDataRecord.rockyName
      this.nameKana = rockyDataRecord.rockyNameKana
      this.address = rockyDataRecord.rockyInfo.address
      //TODO:レベル修正
      this.level = ''
      this.csList = rockyDataRecord.rockyInfo.csList
      this.spaList = rockyDataRecord.rockyInfo.spaList
      this.rockList = this.setRockName(rockyDataRecord.rockList)
      //特記事項をListにセットする
      this.rockList.forEach((rock) => {
        if(rock.dbComment != null && rock.id == this.rockId){
          this.remarkList.push(rock.dbComment)
          this.isShowInfo = true
        }
      })

      this.rockyInfo =rockyDataRecord.rockyInfo
      this.rockyImgPath = this.getRockyThumbPath(rockyDataRecord.imgList[0].path,'360')
      this.rockyLngLat = this.rockyInfo.longitudelatitude
      this.mapCenterLat = this.getLatLng(this.rockyLngLat)[0]
      this.mapCenterLng = this.getLatLng(this.rockyLngLat)[1]
      // 以降の処理で使用できるよう保持しておく
      this.rockyDetailJson = rockyDataRecord
      // Mapに表示するマーカー情報Listを作成する
      let wkList = []
      let wkObj = {
        no:'',
        name: this.rockyName,
        longitudelatitude: this.rockyLngLat,
        address: this.rockyInfo['address'],
        level: this.level,
        url: this.rockyId 
      }
      wkList.push(wkObj)
      this.markerInfo = wkList

      //データのセットが完了した時点でMAPコンポーネントをレンダリングする。
      this.isMapDataAcquired = true

      //ルート情報Listと岩名をセットする
      await this.setRouteDataListAndRockName()

      this.isLoading = false

    },

    //---------------------
    // ルート情報の取得処理
    //---------------------
    async setRouteDataListAndRockName(){

      if((this.isLogin == false && this.isDemoLocky == false) || 
        (this.isLogin == true && this.$user.payment == null && this.isDemoLocky == false)){
        return
      }

      let routeCordinateData = []

      //ルートテーブルを取得する
      await this.getRouteListData(this.rockId)
      .then(response => {
        this.dbRouteList = response.data
      })
      .catch(function (error) {
        console.log('getRouteListData() error')
      })

      //ルート座標テーブルを取得する
      await this.getRoutecoordinateData(this.rockId)
      .then(response => {
        routeCordinateData = response.data
      })
      .catch(function (error) {
        console.log('getRoutecoordinateData() error')
      })
      //座標情報が存在するルートIDのリストを作成する。
      let wkRouteIdList = []

      if(routeCordinateData != undefined) {
        routeCordinateData.forEach(item => {
          item.coordinateList.forEach(route => {
            wkRouteIdList.push(route.routeId)
          })
        })
      }

      let rockList = this.rockyDetailJson.rockList

      //岩Listから対象の岩の情報を抽出する
      let targetRockData = rockList.find(element => element.id == this.rockId);
      if(!targetRockData){
        console.log('targetRockData does not exist') 
        await this.asyncDialog('該当する岩データが存在しません。')
        this.$router.push('/rockyInfo/' + this.rockyId)
      }

      //岩の名前
      this.rockName = targetRockData.name

      //ルート一覧の値をセットする
      this.items = (() => {
        let routeList = []
        let count = 0
        this.dbRouteList.forEach((route) => {
          routeList.push({
            no: ++count,
            routeId: route.routeId,
            route_name: route.routeName,
            level: route.gradeName,
            route_author: '',
            isRouteRecordExist: wkRouteIdList.includes(route.routeId)
          })
        })
        return routeList
      })()
    },
    //---------------------
    // ルート情報の取得処理
    //---------------------
    async getRouteDataList(rockId){

      let rockList = this.rockyDetailJson.rockList

      //岩Listから対象の岩の情報を抽出する
      let targetRockData = rockList.find(element => element.id == rockId);
      if(!targetRockData){
        this.$router.push('/rockyInfo/' + this.rockyId);
      }

      //ルートテーブルを取得する
      await this.getRouteListData(this.rockId)
      .then(response => {
        this.dbRouteList = response.data
      })
      .catch(function (error) {
        console.log('getRouteListData() error')
      })

      let routeCordinateData = []
      //ルート座標テーブルを取得する
      await this.getRoutecoordinateData(rockId)
      .then(response => {
        routeCordinateData = response.data
      })
      .catch(function (error) {
        console.log('getRoutecoordinateData() error')
      })
      //座標情報が存在するルートIDのリストを作成する。
      let wkRouteIdList = []

      if(routeCordinateData != undefined) {
        routeCordinateData.forEach(item => {
          item.coordinateList.forEach(route => {
            wkRouteIdList.push(route.routeId)
          })
        })
      }

      //岩名
      this.rockName = targetRockData.name
      //岩画像のパス
      this.rockyImgPath = this.getRockThumbPath(targetRockData.imgList[0].path,this.rockyId,'360')

      //ルート一覧の値をセットする
      this.items = (() => {
        let routeList = []
        let count = 0
        this.dbRouteList.forEach((route) => {
          routeList.push({
            no: ++count,
            routeId: route.routeId,
            route_name: route.routeName,
            level: route.gradeName,
            route_author: '',
            isRouteRecordExist: wkRouteIdList.includes(route.routeId)
          })
        })

        return routeList
      })()

      //特記事項をListにセットする
      this.remarkList = []
      this.rockList.forEach((rock) => {
        if(rock.dbComment != null && rock.id == this.rockId){
          this.remarkList.push(rock.dbComment)
          this.isShowInfo = true
        }
      })
      //特記事項が存在しない場合はフラグをリセットする
      if(this.remarkList.length == 0 ){
        this.isShowInfo = false
      }
    },
    //--------------------------------------------------------------------------
    //岩に対してログインユーザーの記録とチェックイン履歴が存在するかをどうかを取得する
    //--------------------------------------------------------------------------
    async setRecordAndCheckinHistoryExists() {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-record/record-and-checkin-history-exists'
      await axios.get(jsonUrl,{
        params: {
          "rockyId" : this.rockyId,
          "rockId" : this.rockId,
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      }).then(response => {
        this.recordExist = response.data.recordExist
        this.checkinHistoryExist = response.data.checkinExist
      })
      .catch(err => {
        this.falseInfo = true
      })
    },
    //------------------------------
    //  APIから岩場のルートを取得する
    //------------------------------
    getRouteListData: (rockId) => new Promise((resolve, reject) => {
      let query = '?rockId=' + rockId
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/route-list' + query
      axios.get(jsonUrl)
      .then(res => resolve(res))
      .catch(err => reject(err))
    }),
    //----------------------------
    //  APIから座標データを取得する
    //----------------------------
    getRoutecoordinateData: (rockId) => new Promise((resolve, reject) => {
      let query = '?rockId=' + rockId
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/route-coordinate-list' + query
      axios.get(jsonUrl)
      .then(res => resolve(res))
      .catch(err => reject(err))
    }),
    //---------------
    // 岩場データ取得
    //---------------
    getRockyMasterData: (paramRockyId,isLogin) => new Promise((resolve, reject) => {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/rocky-info?rockyId=' + paramRockyId + '&isLogin=' + isLogin
      axios.get(jsonUrl)
      .then(res => resolve(res))
      .catch(err => reject(err))
    }),
    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    async dispErrorDialog(){
      await this.asyncDialog('データが存在しません')
    },

    //岩の名前に連番を付ける
    setRockName(rockList){
      let count = 0
      rockList.forEach((rock) => {
        count = count + 1
        //let cntStr = count + '．'
        let cntStr = ''
        if(rock.name == null){
          rock.name = cntStr + '（岩名なし）'
        }else{
          rock.name = cntStr + rock.name          
        }
      })
      return rockList
    },
    cnvRouteName(routeName){
      return routeName == null || routeName == '' ? '（ルート名無し）' : routeName
    },
    //---------------
    // 経度緯度を分割
    //---------------
    getLatLng(latLng){
      return latLng.split(',');
    },
    //---------------
    // 画像パスを生成
    //---------------
    createImgPath(path){
      return this.getRockThumbPath(path,this.rockyId,'360')
    },
    //----------------------------------
    // MAPにコンビニと温泉のマーカーを表示
    //----------------------------------
    addCsSpaOnMap(){
      this.$refs.map.addCsSpaMarker(this.csList,this.spaList)
    },
    //------------------------------------------------------
    // 岩マーカー変更時の処理(ルート一覧を表示する)(MAPからよばれる)
    //------------------------------------------------------
    async callRouteList(rockId,rockName){

      // 対象のマーカーをアクティブにする
      this.callChangeRockMarkerActive(rockId,rockName)
      
      //ルートListを表示
      this.isRouteListShow = true;
      this.rockId = rockId
      //ルート一覧のデータ取得とセット
      this.getRouteDataList(rockId)
    },
    //---------------------------------------
    // 対象の岩場のピンをアクティブにする
    //(この画面と子コンポーネントから呼ばれる)
    //---------------------------------------
    callChangeRockMarkerActive(rockId,rockName){
      this.$refs.map.changeRockMarkerActive(rockId,rockName)
    },
    //---------------------------------------
    // Map上の岩場アイコン押下時の処理
    //---------------------------------------
    addRockyMarkerAndDeleteRockMarker(){
      // var alertmsg  = () => {
      //   this.$refs.map.addRockyMarkerAndDeleteRockMarker()
      // }
      // setTimeout(alertmsg, 2000);
      this.$refs.map.addRockyMarkerAndDeleteRockMarker()
    },
    callRockyInfo(){
      this.$router.push('/rockyInfo/' + this.rockyId)
    },
    callRouterPush(path){
      this.$router.push(path)
    },    
    callRouterPushCheckin(){
      this.$router.push('/myPage/' + this.$user.userId +'/checkInHistoryList')
    },
    callRouterPushRecord(){
      this.$router.push('/myPage/' + this.$user.userId +'/rockList?rockyId=' + this.rockyId)
    },
    //------------------
    // ルート画面に遷移
    //------------------
    callRouteImage(data) {
      if(!data.item.isRouteRecordExist){
        // 紐付くルート座標情報が存在しない場合は何もしない
        return
      }

      const routeId = data.item.routeId

      //MAPと岩場画像、岩画像を非表示にする
      this.isRockyImgShow = false

      //ルート画像表示画面に遷移
      //（NavigationDuplicatedエラーを回避するために、無名関数を2こ渡す）
      this.$router.push(
          { name: 'rockyInfo_:rockyId_:rockId_:routeId',
          params:{
            rockyId:this.rockyId,
            rockId:this.rockId,
            rockName:this.rockName,
            routeId:routeId,
            rockyDetailJson:this.rockyInfoApiData,
          }
        },() => {},() => {}
      )

    },
  },
  // コンポーネント
  components: {
    Map,
    Loading,
    FeedBack
  },
  // マウント後
  mounted() {
    this.$nextTick(() => {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      // console.log('nextTick')
    })
  },
  // データ変更後
  updated() {
    // console.log('rock画面のupdated')
  },
  // インスタンス破棄前
  beforeDestroy() {
    // console.log('beforeDestroy')
  }
}
</script>

<style lang="stylus" scoped>
#app .wrapper
  padding-top 0px

#title
  margin-right -15px
  margin-left -15px
  text-align center
  color #fff
  background #03375c
  // font-size 1.5rem
  // display flex
  justify-content center
  align-items center
  // height 4rem
.rockyNameTitle
  font-size 1.5rem
#create-record-row
  margin 0.5rem 0
  text-align right

#map-container
  margin-bottom 1rem
  #rocky-map-inner
    height 300px
    margin-right -15px
    margin-left -15px

//記録作成画面へのリンク
.create-record-row
  margin 0.5rem 0
  text-align right
  .linkExist
    text-decoration underline
    cursor pointer

//特記事項
#info-container
  background #eceeef
  color #df5f6a
  margin 1rem 0
  padding 5px
  border-radius 3px

//岩画像
.col-6,.col-md-4
  padding 5px 5px
.col-6.col-md-4
  .img-container
    width 50vw
    height calc(50vw * 0.75)
    max-width 50vw
    max-height calc(50vw * 0.75)
    min-width 50vw
    min-height calc(50vw * 0.75)
    display flex
    justify-content center
    align-items center

  .img-container-innner
    position relative
    background #374047
    height calc(50vw * 0.71)
    display flex
    justify-content center
    align-items center
    img
      width:auto;
      height:auto;
      max-width:100%;
      max-height:100%;
    .rocky-ban
      position absolute
      color #d2202f
      font-size 1.4rem
      font-weight 600
      left 0
      top 0
      background rgba(222,225,227,0.6)
      padding 2px 0.8rem
    .rocky-name
      position absolute
      color white
      bottom 0
      width 100%
      min-height 30%
      padding-left 0.5rem
      font-size 0.8rem
      background-color rgba(0, 0, 0, 0.5)

//ルートListテーブル
#route-list
  position relative
  min-height 20rem
  #overlay-wrapper
    position absolute
    top 60px
    width 100%
    text-align center
    background linear-gradient(to bottom, rgba(255,255,255,0), #fff 10%);
    z-index 5
    #overlay-not-logined
      #info-area
        padding 1.5rem 0
        span
          font-weight 600
          color #df5f6a
      #button-area
        button
          width 100%
          margin-bottom 2rem

.table
  width 100%
  margin-bottom 1rem
  color #000
  background-color #F5F5F6
  cursor pointer
  .btn-link 
    text-align left
  .col-make-record
    width 100%
    text-align center
    .inner-box
      width 30%
      margin 0 auto
      cursor pointer
      display inline-block
      position relative
      i
        margin 0 auto
      .col-make-recordtext 
        position absolute
        z-index 1
        bottom 100%
        left -25%
        visibility hidden
        width auto
        white-space nowrap
        padding 0.3em 0.5em
        transition opacity 1s
        text-align center
        opacity 0
        color #ffffff
        border-radius 2px
        background-color #606e79
        font-size 0.8rem
      &:hover 
        .col-make-recordtext 
          visibility visible
          opacity 1

.link-button
  font-size 0.9rem
  padding 0rem

//IEの場合、リンクに外枠がついてしまう現象の対策
@media all and (-ms-high-contrast: none)
  #app
    .btn
      box-shadow 0 0

//共通設定を上書き(リンクに外枠がついてしまう現象の対策)
#app
  .btn
    box-shadow unset
  .btn:hover
    box-shadow unset

// モバイルデバイス
@media (max-width 992px)
  .table
    .col-make-record
      .inner-box
        .col-make-recordtext 
          display none

// 大デバイス（デスクトップ, 992px 以上）
@media (min-width 992px)
  .col-6,.col-md-4
    padding 5px 5px
  .col-6.col-md-4
    .img-container-innner
      max-height calc(50vw * 0.4)
      .rocky-name
        min-height 40%

  #route-list
    #overlay-wrapper
      background linear-gradient(to bottom, rgba(236,238,239,0), #fff 10%);
      #overlay-not-logined
        #button-area
          button
            width 60%

</style>